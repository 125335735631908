<template>
    <v-container fluid>
        <tabs
                :currentTab="currentTab"
                :line-class="'tab-item-line'"
                :tab-active-class="'tab-item-active'"
                :tab-class="'tab-item'"
                :tabs="this.tabs"
                :wrapper-class="'body-tabs shadow-tabs'"
                @onClick="handleClick"
        />

        <div class="content mt-6 pa-0">
            <!--reservation types -->
            <div v-if="currentTab === 'tab1'">
                <templates-component/>
            </div>


            <!-- confirmation config -->
            <div v-if="currentTab === 'tab2'">
                <assign-template-component/>
            </div>

        </div>

    </v-container>
</template>

<script>
    import Tabs from 'vue-tabs-with-active-line';
    import TemplatesComponent from "./TemplatesComponent";
    import AssignTemplateComponent from "./AssignTemplateComponent";

    export default {
        components: {
            AssignTemplateComponent,
            TemplatesComponent,
            Tabs
        },
        name: "CashierModulesComponent",
        data: () => {
            return {

                currentTab: 'tab1',
            }
        },
        computed: {
            tabs: function () {
                return [{
                    title: this.$t('settings.lang_templates'),
                    value: 'tab1',
                },
                    {
                        title: this.$t('settings.lang_assignTemplates'),
                        value: 'tab2',
                    }];
            }
        },
        methods: {
            handleClick(newTab) {
                this.currentTab = newTab;
            }
        }
    }
</script>

<style>
    .tabs > .tabs__item, .tabs__item {
        width: auto !important;
        z-index: 1 !important;
    }

    .tabs__item_active, .tabs__active-line, .shadow-tabs .tab-item-line {
        padding-top: 20px !important;
        z-index: 0;
    }

    .tabs__item:hover {
        border: none;
        outline: none;
    }

    .tabs__item:focus {
        border: none;
        outline: none;
    }

</style>