<template>
  <div class="main-card mb-3 card pa-0 ma-0">
    <div class="card-body pa-0">
      <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-title card-header-tab card-header">

        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          {{$t('settings.lang_templates')}}
        </div>
        <div class="btn-actions-pane-right actions-icon-btn ">
          <v-dialog v-model="dialog" max-width="600px" persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="elevation-0"
                  color="primary"
                  dark
                  fab
                  small
                  @click="resetForm()"
              >
                <v-icon>add</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header mb-2">
                <span class="headline">{{ $t('settings.lang_createTemplate') }}</span>
              </v-card-title>
              <v-card-text>
                <v-form ref="form" lazy-validation>
                  <v-row align="center"  justify="center">
                    <v-col class="pb-0 mb-0" cols="12">
                      <v-text-field v-model="form.name" :rules="[v => !!v]" dense
                                    :label="$t('erp.lang_TemplateName')" outlined required></v-text-field>
                    </v-col>
                    <v-col class="pt-0 mt-0" cols="12">
                      <v-label>{{$t('settings.lang_selectGrid')}}:</v-label>
                      <v-item-group v-model="form.grid">
                        <v-container>
                          <v-row>
                            <v-col
                                v-for="grid in this.grids"
                                :key="grid.value"
                                cols="12"
                                md="4"
                            >
                              <v-item v-slot:default="{ active, toggle }"
                                      :value="grid.value">
                                <v-card
                                    :color="active ? 'primary' : ''"
                                    class="d-flex align-center"
                                    dark
                                    height="100"
                                    @click="toggle"
                                >
                                  <v-scroll-y-transition>
                                    <div
                                        class="display-3 flex-grow-1 text-center"
                                    >
                                      <h3>{{ grid.name }}</h3>
                                    </div>
                                  </v-scroll-y-transition>
                                </v-card>
                              </v-item>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-item-group>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>

              <v-card-actions :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-footer text-right">
                <v-col class="text-right pa-0 ma-0" cols="12">
                  <v-spacer/>
                  <v-btn color="error" @click="dialog = false">
                    {{ this.$t('generic.lang_cancel') }}
                  </v-btn>
                  <v-btn v-if="!edit" :disabled="loading" :loading="loading"
                         color="primary"
                         @click="create()">{{ this.$t('generic.lang_create') }}
                  </v-btn>

                  <v-btn v-if="edit" :disabled="loading" :loading="loading"
                         color="primary"
                         @click="update()">{{ this.$t('generic.lang_save') }}
                  </v-btn>
                </v-col>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <v-divider class="pa-1 ma-0"/>

      <v-row>
        <v-col class="pt-0" cols="12">
          <Datatable
              ref="templates"
              :api-endpoint="ENDPOINTS.DATATABLES.SETTINGS.CASHIERMODULES.TEMPLATES"
              :datatable-headers="this.datatableHeaders"
              :elevation="0"
              :excel-columns="excelColumns"
              :permissionDelete="true"
              :permissionEdit="true"
              :v-show="true"
              excel-file-name="cashier modules templates"
              show-delete-buttons
              show-display-buttons
              show-edit-buttons
              show-select-checkbox
              @deleteEntries="deleteEntries"
              @deleteEntry="deleteEntry"
              @displayEntry="displayEntry"
              @editEntry="editEntry"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Datatable from "../../../datatable/Datatable";
import {ENDPOINTS} from "../../../../config";
import {Events} from "../../../../plugins/events";

export default {
  name: "TemplatesComponent",
  components: {Datatable},
  data: () => {
    return {
      ENDPOINTS,
      dialog: false,
      form: {
        name: "",
        grid: 4
      },
      grids: [
        {
          value: 4,
          name: "3*N"
        },
        {
          value: 6,
          name: "2*N"
        },
        {
          value: 12,
          name: "1*N"
        }
      ],
      loading: false,
      edit: false,
      defaultTMPL: {
        name: "",
        grid: 4
      }
    }
  },
  methods: {
    resetForm() {
      this.form = Object.assign({}, this.defaultTMPL);
      this.$refs.form.reset();
    },
    displayEntry(entry) {
      this.$router.push("/settings/cashierSettings/cashiermoduleEdit/" + entry.id);
    },
    update() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;

      this.axios.post(ENDPOINTS.SETTINGS.CASHIERMODULES.TEMPLATES.UPDATE, this.form).then((res) => {
        if (res.data.STATUS === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t("erp.lang_itemSuccessfulAdded"),
            color: "success"
          });
          this.$refs.form.reset();
          this.dialog = false;
          this.$refs.templates.getDataFromApi();
          this.$refs.templates.resetSelectedRows();
        } else if (res.data.STATUS === 'FAILED') {
          Events.$emit("showSnackbar", {
            color: "error",
            message: res.data.msg
          });
        } else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    create() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;

      this.axios.post(ENDPOINTS.SETTINGS.CASHIERMODULES.TEMPLATES.CREATE, this.form).then((res) => {
        if (res.data.STATUS === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t("erp.lang_itemSuccessfulAdded"),
            color: "success"
          });
          this.$refs.form.reset();
          this.dialog = false;
          this.$refs.templates.getDataFromApi();
          this.$refs.templates.resetSelectedRows();
        } else if (res.data.STATUS === 'FAILED') {
          Events.$emit("showSnackbar", {
            color: "error",
            message: res.data.msg
          });
        } else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    delete(idsToDelete) {
      this.$swal({
        title: this.$t('erp.lang_ware_delete_confirm'),
        text: this.$t('erp.lang_ware_delete_confirm'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.axios.post(ENDPOINTS.SETTINGS.CASHIERMODULES.TEMPLATES.DELETE, {
            uuids: idsToDelete
          }).then((res) => {

            if (res.data.STATUS === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t("erp.lang_itemSuccessfulDeleted"),
                color: "success"
              });

              this.$refs.templates.getDataFromApi();
              this.$refs.templates.resetSelectedRows();
            } else if (res.data.STATUS === 'FAILED') {
              Events.$emit("showSnackbar", {
                color: "error",
                message: res.data.msg
              });
            } else {
              Events.$emit("showSnackbar", {
                color: "error",
                message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
              });
            }
          }).catch((err) => {
            Events.$emit("showSnackbar", {
              color: "error",
              message: err.message
            });
          })
        }
      });
    },
    deleteEntry(entry) {
      this.delete([entry.id])
    },
    deleteEntries(ids) {
      this.delete(ids);
    },
    editEntry(entry) {
      this.edit = true;
      this.form = Object.assign({}, entry);
      this.dialog = true;
    }
  },
  computed: {
    datatableHeaders: function () {
      return [
        {
          text: "#",
          value: "id",
          hide: true
        },
        {
          text: this.$t('generic.lang_name'),
          value: "name",
          sortable: false
        },
        {
          text: "",
          value: "grid",
          sortable: false,
          hide: true
        }
      ]
    },
    excelColumns: function () {
      return [
        {
          label: "#",
          field: "id"
        },
        {
          label: this.$t('generic.lang_name'),
          field: "name"
        }
      ]
    },
    formTitle: function () {
      return this.edit ? "Edit template" : "" +
          "";
    }
  }
}
</script>

<style scoped>

</style>