<template>
    <div>
        <page-title :icon=icon :heading="$t('settings.lang_cashierModules')" :subheading="$t('settings.lang_cashierModules')"></page-title>
        <div class="app-main__inner">
            <cashier-modules-component/>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../../Layout/Components/PageTitle.vue";
    import CashierModulesComponent
        from "../../../../components/settings/cashier/cashiermodules/CashierModulesComponent";

    export default {
        components: {
            CashierModulesComponent,
            PageTitle,
        },
        data: () => ({
            icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
        })
    }
</script>