<template>
  <div class="main-card mb-3 card pa-0 ma-0">
    <div class="card-body pa-0">
      <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-title card-header-tab card-header">

        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          {{ $t('settings.lang_assignTemplates') }}
        </div>
        <div class="btn-actions-pane-right actions-icon-btn">
          <v-btn :disabled="loading || loadingCashiers" :loading="loading" @click="save()" class="elevation-0"
                 color="primary"
                 fab small>
            <v-icon>save</v-icon>
          </v-btn>
        </div>
      </div>
      <v-card-text class="pa-0 ma-0">
        <v-data-table :headers="headers" :items="cashiers" :loading="loadingCashiers" class="rounded-t-0">
          <template v-slot:item.identifier="{item}">
            <strong class="primary--text">{{ $t('generic.lang_kasse') }} {{ item.identifier }}</strong>
          </template>

          <template v-slot:item.retail_template="{item}">
            <b-form-select :class="[$vuetify.theme.dark? 'dark-bg' : '']" :options="templates" text-field="name" v-model="item.retail_template"
                           value-field="uuid">

            </b-form-select>
          </template>

          <template v-slot:item.gastro_template="{item}">
            <b-form-select :class="[$vuetify.theme.dark? 'dark-bg' : '']" :options="templates" text-field="name" v-model="item.gastro_template"
                           value-field="uuid">

            </b-form-select>
          </template>

          <template v-slot:item.delivery_template="{item}">
            <b-form-select :class="[$vuetify.theme.dark? 'dark-bg' : '']" :options="templates" text-field="name" v-model="item.delivery_template"
                           value-field="uuid">

            </b-form-select>
          </template>
        </v-data-table>
      </v-card-text>
    </div>
  </div>
</template>

<script>
import Template from "../../customerdisplay/Template";
import {ENDPOINTS} from "../../../../config";
import {Events} from "../../../../plugins/events";

export default {
  name: "AssignTemplateComponent",
  components: {Template},
  data: () => {
    return {
      cashiers: [],
      templates: [],
      loadingCashiers: false,
      loading: false
    }
  },
  computed: {
    headers: function () {
      return [
        {
          text: this.$t('generic.lang_kasse'),
          value: "identifier"
        },
        {
          text: this.$t('generic.lang_gastro'),
          value: "gastro_template"
        },
        {
          text: this.$t('generic.moduleRetailName'),
          value: "retail_template"
        },
        {
          text: this.$t('delivery.lang_delivery'),
          value: "delivery_template"
        }
      ]
    }
  },
  methods: {
    save() {
      this.loading = true;
      this.axios.post(ENDPOINTS.SETTINGS.CASHIERMODULES.ASSING.UPDATE, {
        cashiers: this.cashiers
      }).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          Events.$emit("showSnackbar", {
            color: "success",
            message: this.$t('generic.lang_ActionSuccessful')
          });
        } else if (res.data.STATUS === 'FAILED') {
          Events.$emit("showSnackbar", {
            color: "error",
            message: res.data.msg
          });
        } else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(() => (this.loading = false))
    },
    loadCashiers() {
      this.loadingCashiers = true;
      let promises = [];
      promises.push(this.axios.post(ENDPOINTS.SETTINGS.CASHIERMODULES.ASSING.GET));
      promises.push(this.axios.post(ENDPOINTS.SETTINGS.CASHIERMODULES.TEMPLATES.GETALL));
      Promise.all(promises).then(this.axios.spread((...res) => {
        if (res[0].data.STATUS === "SUCCESS") {
          this.cashiers = res[0].data.cashiers;
        } else if (res.data.STATUS === 'FAILED') {
          Events.$emit("showSnackbar", {
            color: "error",
            message: res.data.msg
          });
        } else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
          });
        }

        if (res[1].data.STATUS === "SUCCESS") {
          this.templates = res[1].data.templates;
        } else if (res.data.STATUS === 'FAILED') {
          Events.$emit("showSnackbar", {
            color: "error",
            message: res.data.msg
          });
        } else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
          });
        }
      })).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
        });
      }).finally(() => (this.loadingCashiers = false))
    }
  },
  mounted() {
    this.loadCashiers();
  }
}
</script>

<style scoped>

</style>